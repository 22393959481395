<template>
	<v-container fluid class="pt-0" v-if="permisoVista('tickets','r')">
		<SettingsDialog
			v-model="dialogSettings"
			:minimumDays="minimumDays"
			@refresh:minimumDays="getMinimumDays()"
		/>
		<DetailsDialog
			v-model="dialogDetails"
			:detailsItem="detailsItem"
			@click:close="closeDialogDetails()"
		/>
		<v-dialog v-model="dialogApprove" persistent max-width="500px">
			<v-card>
				<v-card-title>{{ stateItem.state ? 'Reject' : 'Approve' }} Ticket Item</v-card-title>
				<v-card-text>
					<p
						class="mb-0 text-subtitle-1"
						style="font-size: 18px !important"
					>
						Are you sure you want to {{ stateItem.state ? 'reject' : 'approve' }} this ticket item ?
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="changing"
						@click="closeDialogsState"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="changing"
						:loading="changing"
						@click="changeApproveItem"
						>Accept</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogState" persistent max-width="500px">
			<v-card>
				<v-card-title>Change status to {{ stateItem.state ? 'Cancelled' : 'Approved' }}</v-card-title>
				<v-card-text>
					<p
						class="mb-0 text-subtitle-1"
						style="font-size: 18px !important"
					>
						Are you sure you want to change the status of all items of this ticket to {{ stateItem.state ? 'cancelled' : 'approved' }} ?
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="changing"
						@click="closeDialogsState"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="changing"
						:loading="changing"
						@click="changeStatus"
						>Accept</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CreateTicket
			v-model="dialogCreate"
			:projects="projects"
			:purchaseClasses="purchaseClasses"
			:idMMCallBuyTicket="idMMCallBuyTicket"
			:minimumDays="minimumDays"
			@refresh="initialize()"
		/>
		<DownloadFilesDialog
			v-model="dialogDownloads"
			:downloadItem="downloadItem"
			@click:close="closeDialogDownloads"
		/>
		<v-row>
			<v-col cols="12" class="px-2 py-0 d-flex align-center justify-space-between">
				<v-btn
					v-if="permisoVista('tickets','c')"
					color="primary"
					:disabled="loading || loadingMinDays"
					@click="showDialogCreate()"
				>
					Create Ticket
				</v-btn>
				<v-btn
					v-if="permisoVista('purchasingprocesses-admin','u')"
					color="primary"
					:disabled="loadingMinDays"
					:loading="loadingMinDays"
					@click="showDialogSettings()"
				>
					<v-icon left>mdi-cog</v-icon>
					Configuración
				</v-btn>
			</v-col>
		</v-row>
		<TicketFilters
			v-model="filters"
			:projects="projects"
			:loadingProjects="loadingProjects"
			:purchaseClasses="purchaseClasses"
			:loadingPurchaseClasses="loadingPurchaseClasses"
			:status="status"
			:loadingStatus="loadingStatus"
			:stages="stages"
			:loadingStages="loadingStages"
			:poArray="poArray"
			:loadingPo="loadingPo"
			:ioNumbers="ioNumbers"
			:loadingIONumbers="loadingIONumbers"
			:farNumbers="farNumbers"
			:loadingFARNumbers="loadingFARNumbers"
			:users="users"
			:loadingUsers="loadingUsers"
			:buyers="buyers"
			:loadingBuyers="loadingBuyers"
			:purchaseAreas="purchaseAreas"
			:loadingPurchaseAreas="loadingPurchaseAreas"
			:loading="loading"
			:search="search"
			@update:search="search = $event"
			@change="getTickets"
		/>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="tickets"
					:headers="ticketsHeaders"
					:loading="loading"
					:search="search"
					loading-text="Loading..."
					class="custom-width-header custom-fixed-header"
				>
					<template v-slot:[`item.state`]="{ item }">
						<v-icon
							:color="item.state ? 'success': 'error'"
							size="22"
						>
							{{ item.state ? 'mdi-check-circle' : 'mdi-close-circle' }}
						</v-icon>
					</template>
					<template v-slot:[`item.aging`]="{ item }">
						<div class="d-flex align-center justify-center">
							<v-icon
								class="mr-2"
								:class="{
									'success--text': item.aging >= 3,
									'yellow--text':
										item.aging >= 1 && item.aging <= 2,
									'error--text': item.aging <= 0,
								}"
								small
								>mdi-circle</v-icon
							>
							<p class="mb-0">{{ item.aging }} days</p>
						</div>
					</template>
					<template v-slot:[`item.emergencyApproved`]="{ item }">
						<div class="d-flex align-center justify-center">
							<p class="mb-0 mr-2">
								{{ item.emergencyApproved ? "YES" : "NO" }}
							</p>
							<v-tooltip
								v-if="item.emergencyApproved"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										:disabled="item.downloadingEmergencyForm"
										color="primary"
										@click="showDialogDownloads(item,1)"
										>mdi-file-download</v-icon
									>
								</template>
								<span class="white--text">Download file(s)</span>
							</v-tooltip>
						</div>
					</template>
					<template v-slot:[`item.rfq`]="{ item }">
						<div class="d-flex align-center justify-center">
							<p class="mb-0 mr-2">
								{{ item.rfq ? "YES" : "NO" }}
							</p>
							<v-tooltip
								v-if="item.rfq"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										:disabled="item.downloadingRFQ"
										color="primary"
										@click="showDialogDownloads(item,2)"
										>mdi-file-download</v-icon
									>
								</template>
								<span class="white--text">Download file(s)</span>
							</v-tooltip>
						</div>
					</template>
					<template v-slot:[`item.areas`]="{ item }">
						<p
							v-for="area,index in item.areas"
							class="mb-0"
							:key="`area-${index}`"
						>
							{{ item.areas.length > 1 ? '•' : '' }} {{ area }}
						</p>
					</template>
					<template v-slot:[`item.toolCrib`]="{ item }">
						{{ item.toolCrib || "N/A" }}
					</template>
					<template v-slot:[`item.project`]="{ item }">
						{{ item.project || "N/A" }}
					</template>
					<template v-slot:[`item.budgetQuote`]="{ item }">
						{{ item.budgetQuote ? "YES" : "NO" }}
					</template>
					<template v-slot:[`item.quantity`]="{ item }">
						{{ item.quantity.toFixed(2) }}
					</template>
					<template v-slot:[`item.farNumber`]="{ item }">
						{{ item.farNumber || "N/A" }}
					</template>
					<template v-slot:[`item.ioNumber`]="{ item }">
						{{ item.ioNumber || "N/A" }}
					</template>
					<template v-slot:[`item.status`]="{ item }">
						{{ item.status || 'Pendiente' }}
					</template>
					<template v-slot:[`item.stage`]="{ item }">
						{{ item.stage || 'Pendiente' }}
					</template>
					<template v-slot:[`item.progress`]="{ item }">
						<v-progress-linear
							:value="item.progress"
							height="25"
							color="success"
						>
							<strong>{{ Math.ceil(item.progress) }}%</strong>
						</v-progress-linear>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip
							v-if="permisoVista('tickets','u')"
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialogCreate(item.idMMCallBuyTicket)"
									>mdi-pencil</v-icon
								>
							</template>
							<span class="white--text">Edit</span>
						</v-tooltip>
						<v-tooltip
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialogDetails(item)"
									>mdi-eye</v-icon
								>
							</template>
							<span class="white--text">View Details</span>
						</v-tooltip>
						<template v-if="permisoVista('tickets-approve','u')">
							<v-tooltip
								v-if="item.aprobado"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-2"
										:disabled="loading"
										style="font-size: 18px"
										@click="showDialogApprove(item)"
									>
										mdi-alert-circle
									</v-icon>
								</template>
								<span class="white--text">Reject</span>
							</v-tooltip>
							<v-tooltip
								v-else
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-2"
										:disabled="loading"
										style="font-size: 18px"
										@click="showDialogApprove(item)"
									>
										mdi-check-circle
									</v-icon>
								</template>
								<span class="white--text">Approve</span>
							</v-tooltip>
						</template>
						<v-tooltip
							v-if="permisoVista('tickets','u') && item.state"
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									style="font-size: 18px"
									@click="showDialogState(item)"
								>
									mdi-alert-decagram
								</v-icon>
							</template>
							<span class="white--text">Cancel</span>
						</v-tooltip>
					</template>
					<template v-slot:no-data>
						<v-btn color="primary" @click="initialize()">
							<v-icon size="20" color="white" left>mdi-reload</v-icon>
							Refresh
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CreateTicket from "./MyTickets/CreateTicket.vue";
import DownloadFilesDialog from './MyTickets/DownloadFilesDialog.vue';
import TicketFilters from './MyTickets/TicketFilters.vue';
import DetailsDialog from './MyTickets/DetailsDialog.vue';
import SettingsDialog from './MyTickets/SettingsDialog.vue';
import axios from 'axios';
import qs from "qs";

export default {
	components: { CreateTicket, DownloadFilesDialog, TicketFilters, DetailsDialog, SettingsDialog },
	data() {
		return {
			dialogState: false,
			changing: false,
			dialogApprove: false,
			stateItem: {
				idMMCallBuyTicket: null,
				idMMCallBuyTicketItem: null,
				state: null
			},
			defaultStateItem: {
				idMMCallBuyTicket: null,
				idMMCallBuyTicketItem: null,
				state: null
			},
			loadingMinDays: false,
			minimumDays: 1,
			dialogSettings: false,
			dialogDetails: false,
			detailsItem: {
				idMMCallBuyTicketItem: null,
				idTicket: null,
				comment: null,
				comments: [],
				dialogType: null,
			},
			defaultDetailsItem: {
				idMMCallBuyTicketItem: null,
				idTicket: null,
				comment: null,
				comments: [],
				dialogType: null,
			},
			loadingPurchaseAreas: false,
			purchaseAreas: [],
			loadingBuyers: false,
			buyers: [],
			loadingUsers: false,
			users: [],
			loadingFARNumbers: false,
			farNumbers: [],
			loadingIONumbers: false,
			ioNumbers: [],
			search: null,
			filters: {
				from: null,
				to: null,
				idProject: null,
				emergency: null,
				idPurchaseClass: null,
				status: [4],
				idsMMCallStage: [],
				po: [],
				ioNumbers: [],
				farNumbers: [],
				idsUsuarios: [],
				idsBuyers: [],
				idsAreas: []
			},
			loadingPo: false,
			poArray: [],
			loadingStages: false,
			stages: [],
			dialogDownloads: false,
			downloadItem: {
				type: null,
				files: []
			},
			defaultDownloadItem: {
				type: null,
				files: []
			},
			idMMCallBuyTicket: null,
			loadingStatus: false,
			status: [4],
			loadingPurchaseClasses: false,
			purchaseClasses: [],
			loadingProjects: false,
			projects: [],
			dialogCreate: false,
			tickets: [],
			ticketsHeaders: [
				{
					text: "State",
					align: "center",
					value: "state",
					width: "25px"
				},
				{
					text: "Item",
					align: "center",
					value: "item",
				},
				{
					text: "PR Request Date",
					align: "center",
					value: "prDate",
				},
				{
					text: "Material/SRV Need Date",
					align: "center",
					value: "srvDate",
				},
				{
					text: "Aging",
					align: "center",
					value: "aging",
				},
				{
					text: "Emergency Form Approved?",
					align: "center",
					value: "emergencyApproved",
				},
				{
					text: "RFQ",
					align: "center",
					value: "rfq",
				},
				{
					text: "Requester",
					align: "center",
					value: "requester",
				},
				{
					text: "Area Manager",
					align: "center",
					value: "areaManager",
				},
				{
					text: "Area",
					align: "center",
					value: "areas",
				},
				{
					text: "Tool Crib Code",
					align: "center",
					value: "toolCrib",
				},
				{
					text: "Project",
					align: "center",
					value: "project",
				},
				{
					text: "Budget Quote",
					align: "center",
					value: "budgetQuote",
				},
				{
					text: "General Description",
					align: "center",
					value: "generalDescription",
				},
				{
					text: "Description",
					align: "center",
					value: "description",
				},
				{
					text: "Qty",
					align: "center",
					value: "quantity",
				},
				{
					text: "UM",
					align: "center",
					value: "unity",
				},
				{
					text: "Suggested Supplier",
					align: "center",
					value: "supplier",
				},
				{
					text: "Purchase Class",
					align: "center",
					value: "purchaseClass",
				},
				{
					text: "FAR Number",
					align: "center",
					value: "farNumber",
				},
				{
					text: "IO Number",
					align: "center",
					value: "ioNumber",
				},
				{
					text: "GL Account",
					align: "center",
					value: "glAccount",
				},
				{
					text: "Cost Center",
					align: "center",
					value: "costCenter",
				},
				{
					text: "Buyers",
					align: "center",
					value: "buyers",
				},
				{
					text: "Clerks",
					align: "center",
					value: "clecks",
				},
				{
					text: "Status",
					align: "center",
					value: "status",
				},
				{
					text: "Stage",
					align: "center",
					value: "stage",
				},
				{
					text: "Progress",
					align: "center",
					value: "progress",
				},
				{
					text: "Created At",
					align: "center",
					value: "createAt",
				},
				{
					text: "Last Update",
					align: "center",
					value: "lastUpdate",
				},
				{
					text: "Reassignments",
					align: "center",
					value: "numReasignaciones",
				},
				{
					text: "Actions",
					align: "center",
					value: "actions",
					width: "5%"
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		changeStatus(){
			this.changing = true;

			axios
				.put(`/BuyTickets/CancelarTicketItems/${this.stateItem.idMMCallBuyTicket}`)
				.then( () => {
					this.closeDialogsState();
					this.getTickets();
				})
				.catch( (error) => {
					console.log(error);
				})
				.finally( () => {
					this.changing = false;
				});
		},
		changeApproveItem(){
			this.changing = true;

			axios
				.put('/BuyTickets/AprobarItem',{
					idMMCallBuyTicketItem: this.stateItem.idMMCallBuyTicketItem,
					aprobado: !this.stateItem.state
				})
				.then( () => {
					this.closeDialogsState();
					this.getTickets();
				})
				.catch( (error) => {
					console.log(error);
				})
				.finally( () => {
					this.changing = false;
				});
		},
		closeDialogsState(){
			this.dialogApprove = false;
			this.dialogState = false;
			
			setTimeout(() => {
				this.stateItem = Object.assign({},this.defaultStateItem);
			}, 200);
		},
		showDialogState({ idMMCallBuyTicket, state }){
			this.stateItem.idMMCallBuyTicket = idMMCallBuyTicket;
			this.stateItem.state = state;
			this.dialogState = true;
		},
		showDialogApprove({ idMMCallBuyTicketItem, aprobado }){
			this.stateItem.idMMCallBuyTicketItem = idMMCallBuyTicketItem;
			this.stateItem.state = aprobado;
			this.dialogApprove = true;
		},
		showDialogSettings(){
			this.dialogSettings = true;
		},
		closeDialogDetails() {
			this.dialogDetails = false;

			setTimeout(() => {
				this.detailsItem = structuredClone(this.defaultDetailsItem);
			}, 200);
		},
		showDialogDetails(item){
			this.detailsItem = structuredClone(item);
			this.detailsItem.idMMCallBuyTicketItem = item.idMMCallBuyTicketItem;
			this.detailsItem.dialogType = 2;
			this.dialogDetails = true;
		},
		closeDialogDownloads(){
			this.dialogDownloads = false;
			setTimeout(() => {
				this.downloadItem = structuredClone(this.defaultDownloadItem);
			}, 200);
		},
		showDialogDownloads(item,type){
			this.downloadItem.type = type;
			this.downloadItem.files = structuredClone(type == 1 ? item.emergencyFormFiles : item.rfqFiles);

			this.dialogDownloads = true;
		},
		showDialogCreate(idMMCallBuyTicket = null) {
			this.idMMCallBuyTicket = idMMCallBuyTicket;
			this.dialogCreate = true;
		},
		initialize() {
			this.getTickets();
			this.getProjects();
			this.getPurchaseClasses();
			this.getStatus();
			this.getStages();
			this.getPo();
			this.getIONumbers();
			this.getFARNumbers();
			this.getUsers();
			this.getBuyers();
			this.getPurchaseAreas();
			this.getMinimumDays();
		},
		getMinimumDays(){
			this.loadingMinDays = true;

			axios
				.get('/BuyTickets/DiaMinimo')
				.then( (response) => {
					this.minimumDays = response.data;
				})
				.catch( (error) => {
					this.minimumDays = 1;
					console.log(error);
				})
				.finally( () => {
					this.loadingMinDays = false;
				});
		},
		getPurchaseAreas(){
			this.loadingPurchaseAreas = true;

			axios
				.get('/BuyTickets/GetCompraAreas')
				.then( (response) => {
					this.purchaseAreas = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.purchaseAreas = [];
				})
				.finally( () => {
					this.loadingPurchaseAreas = false;
				});
		},
		getBuyers(){
			this.loadingBuyers = true;

			axios
				.get('/BuyTickets/Jefes')
				.then( (response) => {
					this.buyers = response.data;
				})
				.catch( (error) => {
					this.buyers = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingBuyers = false;
				});
		},
		getUsers(){
			this.loadingUsers = true;

			axios
				.get('/BuyTickets/UsuariosResumen')
				.then( (response) => {
					this.users = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.users = [];
				})
				.finally( () => {
					this.loadingUsers = false;
				});
		},
		getFARNumbers(){
			this.loadingFARNumbers = true;

			axios
				.get('/BuyTickets/GetFarNumbers')
				.then( (response) => {
					this.farNumbers = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.farNumbers = [];
				})
				.finally( () => {
					this.loadingFARNumbers = false;
				});
		},
		getIONumbers(){
			this.loadingIONumbers = true;

			axios
				.get('/BuyTickets/GetIONumbers')
				.then( (response) => {
					this.ioNumbers = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.ioNumbers = [];
				})
				.finally( () => {
					this.loadingIONumbers = false;
				});
		},
		getPo(){
			this.loadingPo = true;

			axios
				.get('/BuyTickets/GetPo')
				.then( (response) => {
					this.poArray = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.poArray = [];
				})
				.finally( () => {
					this.loadingPo = false;
				});
		},
		getStages(){
			this.loadingStages = true;
			
			axios
				.get('/BuyTickets/StagesTodos')
				.then( (response) => {
					this.stages = response.data
				})
				.catch( (error) => {
					this.stages = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingStages = false;
				});
		},
		getStatus(){
			this.loadingStatus = true;

			axios
				.get('/BuyTickets/Status')
				.then( (response) => {
					this.status = response.data;
				})
				.catch( (error) => {
					this.status = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingStatus = false;
				})
		},
		getPurchaseClasses() {
			this.loadingPurchaseClasses = true;

			axios
				.get("/PurchaseClass/GetPurchaseClass")
				.then((response) => {
					this.purchaseClasses = response.data;
				})
				.catch((error) => {
					this.purchaseClasses = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingPurchaseClasses = false;
				});
		},
		getProjects() {
			this.loadingProjects = true;

			axios
				.get("/Project/GetProjects")
				.then((response) => {
					this.projects = response.data;
				})
				.catch((error) => {
					this.projects = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingProjects = false;
				});
		},
		getTickets() {
			this.loading = true;

			axios
				.get('/BuyTickets/TicketsItems',{
					params: {
						...this.filters,
						idsMMCallStage: this.filters.idsMMCallStage.map( (stage) => stage.idMMCallStage )
					},
					paramsSerializer: (params) => qs.stringify(params, { indices: false, skipNulls: true }),
				})
				.then( (response) => {
					this.tickets = response.data;
				})
				.catch( (error) => {
					this.tickets = [];
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.custom-fixed-header >>> thead th:first-child {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: white;
}

.custom-fixed-header >>> tbody td:first-child {
	position: sticky;
	left: 0;
	z-index: 1;
}

.custom-fixed-header >>> tbody tr:nth-child(even) td:first-child {
	background-color: white;
}

.custom-fixed-header >>> tbody tr:nth-child(odd) td:first-child {
	background-color: #f1f1f1;
}

.custom-fixed-header >>> thead th:nth-child(2) {
	position: sticky;
	top: 0;
	left: 72px;
	z-index: 1;
	background-color: white;
	border-right: 1px solid #CCC;
}

.custom-fixed-header >>> tbody td:nth-child(2) {
	position: sticky;
	left: 72px;
	z-index: 1;
	border-right: 1px solid #CCC;
}

.custom-fixed-header >>> tbody tr:nth-child(even) td:nth-child(2) {
	background-color: white;
}

.custom-fixed-header >>> tbody tr:nth-child(odd) td:nth-child(2) {
	background-color: #f1f1f1;
}

.custom-fixed-header >>> tbody tr:nth-child(even):hover {
	background-color: white !important;
}

.custom-fixed-header >>> tbody tr:nth-child(odd):hover {
	background-color: #f1f1f1 !important;
}

.custom-width-header >>> th {
	min-width: 150px;
}
</style>